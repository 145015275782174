@import '../assets/less/less-var.less';
@import '../assets/less/less-func.less';
@import "./reset.less"; // reset.less
@import (inline) "./animation.css"; // 全局动画
@import './global.less'; // global.less
// 富文本
@import './quill.bubble.css';
@import './quill.core.css';
@import './quill.snow.css';
@import "~antd/dist/antd.css"; // 引入官方提供的 less 样式入口文件
@import "~nprogress/nprogress.css"; // 全局 请求loading样式

html,
body {
  font-family: "-apple-system", "Poppins", sans-serif;
  font-weight: normal;
  color: var(--main-fontc);
  font-size: 14px;
  width: 100%;
}

body {
  max-width: 480px;
  margin: 0 auto;
  position: relative;
}

.ant-layout {
  background: #F1F1F1;

}

//隐藏全局滚动条
// @media screen and(min-width:1200px) {
  ::-webkit-scrollbar {
    display: none;
  }
// }


.ant-notification-topRight {
  z-index: 1001 !important;

  .cust-notification {
    padding: 18px 20px;
    margin: 0;
    background: #2a2a2a;
    border-radius: 8px;
    margin-bottom: 10px;

    .ant-notification-notice-message {
      margin: 0;
      font-size: 12px;
      font-weight: 300;
      color: #fff;
      line-height: 18px;

      .cust-notification-content {
        display: flex;
        align-items: center;

        .notification-icon {
          font-size: 20px;
        }

        .notification-title {
          margin-left: 10px;
        }
      }
    }

    .ant-notification-notice-description {
      display: none;
    }
  }
}