@import '../../assets/less/less-var.less';
@import '../../assets/less/less-func.less';
.mobile-menu-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .menu {
    text-align: center;
    font-size: 20px;
    .menu_icon {
      width: 20px;
    }

    .menu_title {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #848484;
    }
  }

  .menu_active {
    .menu_title {
      color: #053476;
    }
  }
}