.inft-fade-enter {
  opacity: 0;
  animation-duration: .3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.inft-fade-appear {
  opacity: 0;
  animation-duration: .3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.inft-fade-leave {
  animation-duration: .3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.inft-fade-enter.inft-fade-enter-active {
  animation-name: inft-fadeIn;
  animation-play-state: running;
}

.inft-fade-appear.inft-fade-appear-active {
  animation-name: inft-fadeIn;
  animation-play-state: running;
}

.inft-fade-leave.inft-fade-leave-active {
  animation-name: inft-fadeOut;
  animation-play-state: running;
}

@keyframes inft-fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes inft-fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
