@import '../../assets/less/less-var.less';
@import '../../assets/less/less-func.less';
#pass_header {
    width: 100%;
    max-width: 480px;
    height: @mobileHeight;
    position: fixed;
    bottom: 0;
    z-index: 1001;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 5px 13px 0px rgba(87, 66, 36, 0.10);
    background: #FFFFFF;
    // border-radius: 28px 28px 0px 0px;
}